<template>
  <div class="search-page-container">
    <div class="top-search-container">
      <van-form @submit="onSubmit">
        <van-field v-model="state.text" placeholder="微生物多样性分析课程">
          <template #right-icon>
            <v-iconFontSymbol
              :icon="'iconsearch'"
              :style="{ 'font-size': '0.14rem' }"
            />
          </template>
        </van-field>
      </van-form>
      <span class="cancel" @click="toHome">取消</span>
    </div>
    <div style="height: 65px"></div>

    <div
      class="empty-container"
      v-if="!state.listHiden && state.videoList.length == 0"
    >
      <div>
        <v-iconFontSymbol :icon="'iconsearch1'" style="font-size:1.8rem" />
        <div class="text-containers">换个关键词再搜索吧</div>
      </div>
    </div>
    <div v-if="state.listHiden">
      <div class="search-history-container" v-if="state.words_list.length > 0">
        <div class="middle-titles">
          <span>最近搜索</span>
          <span @click="deleteClick">
            <v-iconFontSymbol :icon="'icondelete'" style="font-size: 0.2rem" />
          </span>
        </div>
        <div class="middle-lists">
          <div
            v-for="(item, index) in state.words_list"
            :key="index"
            @click="toHisClick(item)"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="state.listHiden">
      <div class="hot-search-container" v-if="state.hot_words_list.length > 0">
        <div class="middle-titles">
          <span>热门搜索</span>
        </div>
        <div class="hot-search-list">
          <div
            class="hot-list"
            v-for="(item, index) in state.hot_words_list"
            :key="index"
            @click="toHisClick(item)"
          >
            <span>{{ index + 1 }}</span>
            <span>{{ item }}</span>
            <v-iconFontSymbol :icon="'iconhot'" style="font-size: 0.18rem" />
          </div>
        </div>
      </div>
    </div>

    <div class="hot-video-list" v-if="!state.listHiden">
      <div
        class="list-container"
        v-for="(item, index) in state.videoList"
        :key="index"
        @click="toDetail(item.video_id)"
      >
        <div class="left-contents">
          <img :src="item.surface" />
          <div class="bottom-right-times">{{ item.video_time }}</div>
        </div>
        <div class="right-contents">
          <div class="title">{{ item.video_name }}</div>
          <div class="tips">
            <span
              v-for="(data, index) in item.video_tag.slice(0, 2)"
              :key="index"
              >{{ data }}</span
            >
          </div>
          <div class="states">
            <v-iconFontSymbol
              :icon="'iconstudy'"
              class="left-container-svg"
              :style="{ 'font-size': '0.14rem' }"
            />
            {{ item.play_times }}人已学
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import IconFontSymbol from "@/components/IconFontSymbol.vue";
import { Toast } from "vant";

export default {
  name: "Search",
  components: {
    "v-iconFontSymbol": IconFontSymbol
  },
  setup() {
    const state = reactive({
      text: "",
      listHiden: true,
      words_list: [],
      hot_words_list: [],
      videoList: []
    });
    const router = useRouter();
    const { proxy } = getCurrentInstance();

    const toHome = () => {
      router.replace({
        name: "home"
      });
    };

    const getUserWords = async () => {
      let res = await proxy.$request.handleFetchGet("college/user_words");
      state.words_list = res.words_list;
    };

    const putUserWords = async text => {
      await proxy.$request.handleFetchPostJson("college/user_words", {
        words: text
      });
    };

    const deleteSearch = async () => {
      await proxy.$request.handleFetchPut("college/user_words");
      Toast("删除成功");
      getUserWords();
    };

    const getHotWords = async () => {
      let res = await proxy.$request.handleFetchGet("college/hot_words");
      let new_list = [];
      if (res.words_list) {
        res.words_list.forEach(item => {
          if (item !== "") {
            new_list.push(item);
          }
        });
      }
      state.hot_words_list = new_list;
    };

    const putHotWords = async text => {
      await proxy.$request.handleFetchPostJson("college/hot_words", {
        words: text
      });
    };

    const getVideoList = async params => {
      const res = await proxy.$request.handleFetchPost(
        "college/video_list/",
        params
      );
      state.videoList = res.video_list || [];
    };

    const onSubmit = () => {
      let params = {
        video_name: state.text
      };
      if (state.text !== "") {
        state.listHiden = false;
        putUserWords(state.text);
        putHotWords(state.text);
        getVideoList(params);
      } else {
        state.videoList = [];
        state.listHiden = true;
      }
    };

    const deleteClick = () => {
      deleteSearch();
    };

    const toHisClick = value => {
      let params = { video_name: value };
      state.text = value;
      state.listHiden = false;
      getVideoList(params);
    };

    const toDetail = video_id => {
      router.push({
        path: "/hotDetail",
        name: "hotDetail",
        query: {
          isHot: true,
          video_id: video_id
        }
      });
    };

    onMounted(() => {
      getUserWords();
      getHotWords();
    });

    return {
      state,
      toHome,
      onSubmit,
      deleteClick,
      toHisClick,
      toDetail
    };
  }
};
</script>
